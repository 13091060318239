import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import { useUpdateLeaseFieldsMutation } from '../../api/leases/leaseAPI';
import BaseModal from '../../reusable/BaseModal';
import { Box, Button, Divider } from '@mui/material';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy_icon.svg';
import theme from '../../styles/theme';
import AppTypography from '../../design/AppTypography';
import copy from 'copy-to-clipboard';
import React from 'react';
import { Form } from 'react-final-form';
import FormPage from '../../reusable/FormUI/FormPage';
import SubmitButton from '../../design/Buttons/SubmitButton';
import { makeStyles } from 'tss-react/mui';
import { FormConfig } from '../../design/Forms/interfaces';
import {
    composeValidators,
    maxLength,
    minLength,
    required,
} from '../../utils/validation';
import { FsaId } from './LeasePayments';

interface OwnProps {
    handleClose: () => void;
    isShowing: boolean;
    legalDescription?: string;
    initData?: FsaId;
    leaseId?: string;
    landId?: string;
}

export default function AddFieldModal({
    handleClose,
    isShowing,
    legalDescription,
    leaseId,
    landId,
    initData,
}: OwnProps) {
    const { classes } = useStyles();

    const { handleSubmit, isLoading } = useHandleSubmitRTK({
        useRtkHook: useUpdateLeaseFieldsMutation,
        successMessage: 'Field added successfully',
        onSuccess: () => {
            handleClose();
        },
    });
    function handleFieldSubmit(values: FsaId) {
        handleSubmit({
            leaseId,
            landId,
            mapEmbedCode: values.mapEmbedCode,
        });
    }
    return (
        <BaseModal
            size={'medium'}
            isShowing={isShowing}
            onClose={handleClose}
            header={'Add Map'}>
            <>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={2}
                    bgcolor={theme.palette.backgroundB.backgroundB1}
                    p={theme.spacing(2, 3)}>
                    <Box display={'flex'} columnGap={1}>
                        <Box
                            display={'flex'}
                            width={'80%'}
                            flexDirection={'column'}
                            rowGap={1}>
                            <AppTypography
                                variant={'font12'}
                                color={'text.neutral10'}
                                fontWeight={'regular'}
                                text={'Legal Description'}
                            />{' '}
                            <AppTypography
                                variant={'font12'}
                                color={'text.neutral9'}
                                fontWeight={'regular'}
                                text={legalDescription}
                                isHidden={!legalDescription}
                            />
                        </Box>
                        {legalDescription && (
                            <Box width={'17%'} flexDirection={'column'}>
                                <Button
                                    onClick={() => copy(legalDescription)}
                                    endIcon={<CopyIcon />}>
                                    Copy
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Form
                        onSubmit={handleFieldSubmit}
                        initialValues={initData?.mapEmbedCode}>
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} id="fieldForm">
                                <FormPage formConfig={formLandConfig} />
                            </form>
                        )}
                    </Form>
                </Box>
                <Divider orientation="horizontal" />
                <div className={classes.bottomContainer}>
                    <Button onClick={handleClose} color={'secondary'}>
                        Cancel
                    </Button>
                    <SubmitButton
                        form={'fieldForm'}
                        variant={'text'}
                        isLoading={isLoading}
                        text={'Save'}
                    />
                </div>
            </>
        </BaseModal>
    );
}
const useStyles = makeStyles()((theme) => ({
    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
        height: 70,
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.neutralWhite,
        borderRadius: '0px 0px 12px 12px',
    },
}));
export const formLandConfig: FormConfig[] = [
    {
        formField: {
            name: 'mapEmbedCode',
            type: 'textarea',
            label: 'Map Frame',
            isRequired: true,
            tooltip: {
                text: 'Paste the map embed code here',
            },
            validation: composeValidators(
                minLength(1),
                maxLength(1024),
                required,
            ),
        },
        renderProps: {
            placeholder: '',
            resize: 'none',
            isDisabled: false,
        },
    },
];
