import { useSorting } from '../../design/GenericTable/useSorting';
import { usePagination } from '../../design/GenericTable/useBackendPagination';
import { TableColumn } from '../../design/GenericTable/GenericTable';
import { Box, Button } from '@mui/material';
import LinkText from '../../design/Fields/LinkText';

import { useGetEmergencyReadinessPlansQuery } from '../../api/emergencyReadiness/emergencyReadiness';
import { EmergencyReadinessResponse, pathMapper } from './model';
import { dateTimeConverterFromUTC } from '../../utils/dateFormat';
import PercentageCell from '../../reusable/PercentageCell';
import { useNavigate } from 'react-router-dom';
import Paths from '../../router/paths';

export default function useInProgressTable() {
    const { sortKey, sortOrder, handleSort } = useSorting('updatedAt', 'asc');

    const navigate = useNavigate();

    function handleContinueClick(id: string, lastActivePage = 0) {
        navigate(`/${Paths.emergencyReadiness}?organizationId=${id}`, {
            state: { stepNumber: lastActivePage },
        });
    }

    const {
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleItemsPerPageChange,
    } = usePagination(1, 25);

    const { data: responseData, isLoading } =
        useGetEmergencyReadinessPlansQuery({
            status: 'IN_PROGRESS',
            sort: `${sortKey},${sortOrder}`,
            page: currentPage - 1,
            size: itemsPerPage,
        });
    const columns: TableColumn<EmergencyReadinessResponse>[] = [
        {
            header: {
                label: 'Name',
                sortable: true,
                sorting: {
                    id: 'organization.name',
                    direction:
                        sortKey === 'organization.name' ? sortOrder : 'desc',
                    isSorted: sortKey === 'organization.name',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <Box display={'flex'} alignItems={'center'} columnGap={1}>
                    <LinkText
                        to={`/${Paths.landpartners}/${pathMapper[data.organization.type]}/${data.organization.id}`}
                        text={`${data.organization.name}`}
                        variant={'font14'}
                    />
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Date Completed',
                sortable: false,
                sorting: {
                    id: 'updatedAt',
                    direction: sortKey === 'updatedAt' ? sortOrder : 'desc',
                    isSorted: sortKey === 'updatedAt',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>{dateTimeConverterFromUTC(data?.updatedAt)}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Start Date',
                sortable: true,
                sorting: {
                    id: 'startDate',
                    direction: sortKey === 'startDate' ? sortOrder : 'desc',
                    isSorted: sortKey === 'startDate',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>{dateTimeConverterFromUTC(data?.createdAt)}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },

        {
            header: {
                label: 'Last Update',
                sortable: true,
                sorting: {
                    id: 'lastUpdated',
                    direction: sortKey === 'lastUpdated' ? sortOrder : 'desc',
                    isSorted: sortKey === 'lastUpdated',
                },
                onClick: handleSort,
            },
            cellRender: (data) => (
                <>{dateTimeConverterFromUTC(data?.updatedAt)}</>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Submission Progress',
                sortable: false,
            },
            cellRender: (data) => (
                <PercentageCell progress={data.percentageCompleted} />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: '',
                sortable: false,
                onClick: handleSort,
            },
            cellRender: (data) => (
                <Box display="flex" columnGap={1}>
                    <Button
                        onClick={() =>
                            handleContinueClick(
                                data.organization.id,
                                data.lastActiveStep,
                            )
                        }>
                        Continue
                    </Button>
                </Box>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];
    const tableConfig = {
        columns,
        pageable: true,
        handlePageChange,
        handleItemsPerPageChange,
        initialPageSize: 25,
        initialPage: 1,
        expandable: false,
    };
    const totalCount = responseData?.totalCount || 0;
    const data = responseData?.data || [];
    return { data, totalCount, tableConfig, isLoading };
}
