import { Box, Grid } from '@mui/material';
import EmergencyContactCard from './EmergencyContactCard';
import { EmergencyContactData } from '../model';

interface OwnProps {
    data: EmergencyContactData[];
    setContacts: (
        data:
            | EmergencyContactData[]
            | ((prev: EmergencyContactData[]) => EmergencyContactData[]),
    ) => void;
}

export default function EmergencyContactCardList({
    data,
    setContacts,
}: OwnProps) {
    function handleContactTypeCheck(event: string[], id: string) {
        const updatedContacts = data.map((contact) => {
            if (contact.contact.id === id) {
                return {
                    ...contact,
                    contactTypes: event,
                };
            }
            return contact;
        });
        setContacts(updatedContacts);
    }

    return (
        <Box width={'100%'} pt={2}>
            <Grid container spacing={2}>
                {data?.map((contact: EmergencyContactData, index: number) => (
                    <Grid key={index} item xs={6}>
                        <EmergencyContactCard
                            setContacts={setContacts}
                            data={contact?.contact}
                            handleContactTypeCheck={handleContactTypeCheck}
                            value={contact?.contactTypes}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
