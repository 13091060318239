import { Box, Divider } from '@mui/material';
import LandPartnerMainSection, {
    LandPartnerMainSectionProps,
} from './LandPartnerMainSection';
import LandPartnerAdditionalSection from './LandPartnerAdditionalSection';
import {
    LandPartnerUpdate,
    SocialMedia,
} from '../../../features/ViewLandownersDetails/LandPartnerDetails';
import { createBlobUrl } from '../../../utils/dataConvertor';
import { PrimaryAdditionalInfoProps } from './PrimaryAdditionalInfo';
import { Address } from '../../../features/CreateLandPartner/LandPartnerWizard';
import { Member, MemberListResp } from './MemberProfile';
import ActionButton, {
    ActionMenuItem,
} from '../../../design/Buttons/ActionButton';
import React, { useEffect, useState } from 'react';
import PATHS from '../../../router/paths';
import { useNavigate, useParams } from 'react-router-dom';
import ArchivedLandPartner from '../../../features/ViewLandownersDetails/ArchivedLandPartner';
import { useGetLandPartnersContactsQuery } from '../../../api/landpartners/landPartnersAPI';
import useManagePermission from '../../../hooks/useManagePermission';
import { useGetEmergencyReadinessStatusQuery } from '../../../api/emergencyReadiness/emergencyReadiness';

interface EmergencyReadinessStatus {
    exist: boolean;
    status: string;
}
interface OwnProps {
    landPartner?: LandPartnerUpdate;
}

export default function LandPartnerDetailContainer({ landPartner }: OwnProps) {
    const [isDeactivateModalShowing, setIsDeactivateModalShowing] =
        useState(false);
    const [labelName, setLabelName] = useState('');
    const { id } = useParams<{ id: string }>();
    const { checkPermission } = useManagePermission();

    const navigate = useNavigate();

    const { data } = useGetLandPartnersContactsQuery({ id });
    const { data: emergencyStat, isLoading } =
        useGetEmergencyReadinessStatusQuery({ id });

    function emergencyLabel(emergencyStat: EmergencyReadinessStatus): string {
        if (emergencyStat?.status === 'IN_PROGRESS')
            return ' Continue Emergency Readiness';
        if (emergencyStat?.status === 'COMPLETED')
            return 'Edit Emergency Readiness';
        if (!emergencyStat?.exist) return 'Start Emergency Readiness Plan';

        return 'Start Emergency Readiness Plan';
    }

    useEffect(() => {
        setLabelName(emergencyLabel(emergencyStat));
    }, [emergencyStat]);

    const actionButtonItems: ActionMenuItem[] = [
        {
            label: `Edit`,
            onClick: () => navigate(PATHS.update),
            hidden: !checkPermission('landPartners', 'modify'),
        },
        {
            label: 'Archive',
            onClick: () => setIsDeactivateModalShowing(true),
            hidden: !checkPermission('landPartners', 'modify'),
        },
        {
            label: labelName,
            onClick: () =>
                navigate(
                    `/${PATHS.services}/${PATHS.emergencyReadiness}/${PATHS.create}?organizationId=${id}`,
                ),
            hidden: !checkPermission('services', 'read') || isLoading,
        },
    ];
    return (
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <Box display={'flex'} columnGap={2} width={'100%'}>
                {landPartner && (
                    <Box display={'flex'}>
                        <LandPartnerMainSection
                            {...mainSectionConvertor(landPartner)}
                        />
                    </Box>
                )}
                <Divider orientation={'vertical'} flexItem variant="middle" />
                {landPartner && (
                    <LandPartnerAdditionalSection
                        landPartnerId={id}
                        primaryContact={primaryContactConvertor(data)}
                        members={membersConvertor(data)}
                        membersCount={data?.members?.length}
                        primaryInfo={primaryAdditionalDataConvertor(
                            landPartner,
                        )}
                    />
                )}
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'}>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Box>
            {isDeactivateModalShowing && (
                <ArchivedLandPartner
                    isShowing={isDeactivateModalShowing}
                    id={id}
                    onClose={() => setIsDeactivateModalShowing(false)}
                />
            )}
        </Box>
    );
}

function primaryContactConvertor(data: MemberListResp): Member[] {
    return data?.primaryContact
        ? [
              {
                  id: data.primaryContact.id,
                  avatar: {
                      avatar:
                          data.primaryContact?.avatar &&
                          data.primaryContact?.avatar?.fileType &&
                          createBlobUrl(
                              data.primaryContact?.avatar?.contactAvatar,
                              data.primaryContact?.avatar?.fileType,
                          ),
                      name: `${data.primaryContact.firstName} ${data.primaryContact.lastName}`,
                  },
                  name: `${data.primaryContact.firstName} ${data.primaryContact.lastName}`,
                  role: data.primaryContact.contactRole,
              },
          ]
        : [];
}

function membersConvertor(data: MemberListResp): Member[] {
    return data?.members
        ?.map((member) => {
            return {
                id: member.id,
                avatar: {
                    avatar:
                        member.avatar &&
                        member.avatar.fileType &&
                        createBlobUrl(
                            member.avatar.contactAvatar,
                            member.avatar.fileType,
                        ),
                    name: `${member.firstName} ${member.lastName}`,
                },
                name: `${member.firstName} ${member.lastName}`,
                role: member.contactRole,
            };
        })
        .slice(0, 6);
}

function socialMediaConvertor(data: LandPartnerUpdate): SocialMedia {
    return {
        facebook: data.socialMedia?.facebook,
        instagram: data.socialMedia?.instagram,
        linkedin: data.socialMedia?.linkedin,
        twitter: data.socialMedia?.twitter,
    };
}

function primaryAdditionalDataConvertor(
    data: LandPartnerUpdate,
): PrimaryAdditionalInfoProps {
    return {
        address: addressConvertor(data.billingAddress),
        primaryEmail: data?.email,
    };
}

function mainSectionConvertor(
    data: LandPartnerUpdate,
): LandPartnerMainSectionProps {
    return {
        avatar:
            data?.landPartnerLogo?.companyLogo &&
            data?.landPartnerLogo?.fileType &&
            createBlobUrl(
                data?.landPartnerLogo?.companyLogo,
                data?.landPartnerLogo?.fileType,
            ),
        name: data.name,
        relationshipStartDate: data.relationshipStartDate,
        socialMedia: socialMediaConvertor(data),
        status: data.status,
        taxId: data.taxId,
    };
}

function addressConvertor(data: Address): string {
    return data
        ? [data.address1, data.address2, data.city, data.state, data.zipCode]
              .filter(Boolean)
              .join(', ')
        : 'N/A';
}
