import React from 'react';
import {
    useUpdateEmergencyReadinessMutation,
    useSubmitEmergencyReadinessMutation,
} from '../../api/emergencyReadiness/emergencyReadiness';
import WizardForm from '../../design/Forms/WizardForm';
import arrayMutators from 'final-form-arrays';
import EmergencyContactsForm from './EmergencyContactPage/EmergencyContactsForm';
import RiskAssessmentForm from './RiskAssessmentForm';
import CriticalResourcesProcessesForm from './CriticalResourcesProcessesForm';
import EvacuationSafetyForm from './EvacuationSafetyForm';
import CommunicationForm from './CommunicationForm';
import CropProtectionForm from './CropProtectionForm';
import ResourceManagementForm from './ResourceManagementForm';
import RecoveryProceduresForm from './RecoveryProceduresForm';
import EnvironmentalConcernsForm from './EnvironmentalConcernsForm';
import InsuranceFinancialPreparednessForm from './InsuranceFinancialPreparednessForm';
import ExternalAssistanceForm from './ExternalAssistanceForm';
import TrainingPreparednessForm from './TrainingPreparednessForm';
import ContinuityOfOperationsForm from './ContinuityOfOperationsForm';
import useHandleSubmitRTK from '../../hooks/useHandleSubmit';
import PATHS from '../../router/paths';
import { useNavigate } from 'react-router-dom';
import { EmergencyFormValues, EmergencyReadinessValues } from './model';
import { Field } from 'react-final-form';
interface OwnProps {
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    planId?: string;
    initialValues: EmergencyReadinessValues;
}

export default function EmergencyReadinessFormPage({
    pageNumber,
    setPageNumber,
    planId,
    initialValues,
}: OwnProps) {
    const navigate = useNavigate();

    const [updatePlan] = useUpdateEmergencyReadinessMutation();

    const { handleSubmit, isLoading: isCreating } = useHandleSubmitRTK({
        useRtkHook: useSubmitEmergencyReadinessMutation,
        onSuccess: () =>
            navigate(`/${PATHS.services}/${PATHS.emergencyReadiness}`),
        successMessage: 'Emergency Readiness created successfully',
    });

    const handleAutosaveOnFormChange = debounce(
        (values: EmergencyFormValues) => {
            const valuesToSubmit = formValueSubmitAdapter(
                values,
            ) as EmergencyReadinessValues;
            updatePlan({
                id: planId,
                body: { ...valuesToSubmit, lastActiveStep: pageNumber },
            });
        },
        2000,
    );
    function handlePlanningSubmit() {
        return handleSubmit(planId);
    }

    return (
        <WizardForm
            key={JSON.stringify(initialValues)}
            initialValues={initialValues}
            isLoading={isCreating}
            onSubmit={handlePlanningSubmit}
            onChange={(values: EmergencyFormValues) =>
                handleAutosaveOnFormChange(values)
            }
            mutators={arrayMutators as never}
            setStep={setPageNumber}
            step={pageNumber}>
            <Field
                name={'emergencyContacts'}
                render={({ input }) => <EmergencyContactsForm input={input} />}
            />
            <RiskAssessmentForm />
            <CriticalResourcesProcessesForm />
            <EvacuationSafetyForm />
            <CommunicationForm />
            <CropProtectionForm />
            <ResourceManagementForm />
            <RecoveryProceduresForm />
            <EnvironmentalConcernsForm />
            <InsuranceFinancialPreparednessForm />
            <ExternalAssistanceForm />
            <TrainingPreparednessForm />
            <ContinuityOfOperationsForm />
        </WizardForm>
    );
}

function debounce(func: (args: EmergencyFormValues) => void, wait: number) {
    let timeout: NodeJS.Timeout;
    return function executedFunction(args: EmergencyFormValues) {
        const later = () => {
            clearTimeout(timeout);
            func(args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
function formValueSubmitAdapter(
    values: EmergencyFormValues,
): EmergencyReadinessValues {
    return {
        ...values,
        recoveryProcedures: {
            recoveryPlan: values.recoveryProcedures?.recoveryPlan,
            primaryLeadId: values.recoveryProcedures?.primaryLead?.id,
            secondaryLeadId: values.recoveryProcedures?.secondaryLead?.id,
        },
        emergencyContacts: values.emergencyContacts?.map((contact) => ({
            contactId: contact.contact.id,
            contactTypes: contact.contactTypes,
        })),
    } as EmergencyReadinessValues;
}
