import { SolutionInProgress } from './useSolutionsInProgressTable';

export const solutionsInProgressMockData: SolutionInProgress[] = [
    {
        id: '1',
        name: 'Lewis Carroll',
        solution: 'Emergency Readiness',
        totalDays: 12,
        lastActiveDate: '10 days ago',
        progress: 10,
    },
    {
        id: '2',
        name: 'Alice Liddell',
        solution: 'Emergency Readiness',
        totalDays: 132,
        lastActiveDate: '4 days ago',
        progress: 25,
    },
    {
        id: '3',
        name: 'Charles Dodgson',
        solution: 'Emergency Readiness',
        totalDays: 155,
        lastActiveDate: '3 days ago',
        progress: 90,
    },
    {
        id: '4',
        name: 'Frances Jane Lutwidge',
        solution: 'Emergency Readiness',
        totalDays: 197,
        lastActiveDate: '18 days ago',
        progress: 75,
    },
    {
        id: '5',
        name: 'John Ruskin',
        solution: 'Emergency Readiness',
        totalDays: 283,
        lastActiveDate: '41 days ago',
        progress: 95,
    },
];
