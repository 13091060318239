import { OAKENApi } from '../OAKENApi';
import { DOCUMENTS, LAND_PARTNERS, LEASES } from '../tags';
import { ENDPOINTS } from '../endpoints';
import { HTTP_METHOD } from '../auth/http';
import { Lease } from '../../pages/LandPortfolio/TableConfig';
import { getListProvidesTags } from '../generic';
import { LeaseHistoryData } from '../../features/ViewLeaseHistory/useLeaseHistoryTable';
import { DraftLeases } from '../../pages/LandPortfolio/DraftLeaseTable';

interface PageParams {
    page: number;
    size: number;
}

export interface LeaseSearchResult {
    id: string;
    name: string;
}

interface SearchLeasesParams extends PageParams {
    landPartnerId: string;
}

export const leaseAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getLeaseList: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            transformResponse: (
                data: Lease[],
                meta,
            ): { data: Lease[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LAND_PARTNERS.LAND_PARTNERS),
        }),
        getOneLease: builder.query({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: [LEASES.LEASES],
        }),
        createLease: builder.mutation({
            query: ({ leaseFile, supportingFile, lease }) => {
                const formData = new FormData();
                leaseFile && formData.append('leaseFile', leaseFile[0]);
                supportingFile &&
                    supportingFile.forEach((file: File) => {
                        formData.append(`supportingFile`, file, file.name);
                    });
                lease?.id && formData.append('draftId', lease?.id);
                formData.append(
                    'lease',
                    new Blob([JSON.stringify(lease)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [LEASES.LEASES],
        }),
        updateLease: builder.mutation({
            query: ({ lease }) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root,
                    method: HTTP_METHOD.PUT,
                    body: lease,
                };
            },
            invalidatesTags: [LEASES.LEASES],
        }),

        getLeaseDashboard: builder.query({
            query: () => {
                return (
                    process.env.REACT_APP_LEASE_API_HOST +
                    ENDPOINTS.leases.root +
                    '/dashboard'
                );
            },
            providesTags: [LEASES.LEASES],
        }),
        getUpcomingLeaseRenewals: builder.query({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root +
                        '/dashboard/renewals',
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: [],
        }),
        getRecentUploads: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_DOCUMENT_API_HOST +
                        ENDPOINTS.documents.root,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            providesTags: [],
        }),
        getLeaseDocuments: builder.query({
            query: (leaseId) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leaseDocuments.root +
                        '/lease' +
                        `/${leaseId}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: [DOCUMENTS.DOCUMENTS],
        }),
        documentsUpload: builder.mutation({
            query: ({ leaseFile, id }) => {
                const formData = new FormData();
                formData.append('metadata', 'na');
                leaseFile && formData.append('leaseFile', leaseFile[0]);
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root +
                        `/${id}` +
                        '/documents/upload',
                    method: HTTP_METHOD.POST,
                    body: formData,
                    responseHandler: (response) => response.text(),
                };
            },
            invalidatesTags: [DOCUMENTS.DOCUMENTS],
        }),
        leaseHistory: builder.query({
            query: ({ leaseId, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.history +
                        `/${leaseId}`,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            transformResponse: (
                data: LeaseHistoryData[],
                meta,
            ): { data: LeaseHistoryData[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LEASES.LEASES),
        }),
        getLeaseDrafts: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.drafts,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            transformResponse: (
                data: DraftLeases[],
                meta,
            ): { data: DraftLeases[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LEASES.LEASES),
        }),
        copyLease: builder.mutation({
            query: ({ id }) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root +
                        `/${id}/copy`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [LEASES.LEASES],
        }),
        createLeaseDraft: builder.mutation({
            query: ({ leaseFile, supportingFile, lease }) => {
                const formData = new FormData();
                leaseFile && formData.append('leaseFile', leaseFile[0]);
                supportingFile &&
                    supportingFile.forEach((file: File) => {
                        formData.append(`supportingFile`, file, file.name);
                    });
                formData.append(
                    'lease',
                    new Blob([JSON.stringify(lease)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.drafts,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [LEASES.LEASES],
        }),
        deleteLeaseDraft: builder.mutation({
            query: ({ id }) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.drafts +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: [LEASES.LEASES],
        }),
        updateLeaseDraft: builder.mutation({
            query: ({ id, leaseFile, supportingFile, lease }) => {
                const formData = new FormData();
                leaseFile && formData.append('leaseFile', leaseFile[0]);
                supportingFile &&
                    supportingFile.forEach((file: File) => {
                        formData.append(`supportingFile`, file, file.name);
                    });
                formData.append(
                    'lease',
                    new Blob([JSON.stringify(lease)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.drafts +
                        `/${id}`,
                    method: HTTP_METHOD.PUT,
                    body: formData,
                };
            },
            invalidatesTags: [LEASES.LEASES],
        }),
        getArchivedLeases: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root +
                        '/archives',
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            transformResponse: (
                data: Lease[],
                meta,
            ): { data: Lease[]; totalCount: number } => {
                const xTotalCount =
                    meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, LEASES.LEASES),
        }),
        archiveLease: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root +
                        `/${id}/archive`,
                    method: HTTP_METHOD.POST,
                };
            },
        }),
        restoreLease: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root +
                        `/${id}/archive/restore`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: [LEASES.LEASES],
        }),
        deleteLease: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root +
                        `/${id}/archive`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: [LEASES.LEASES],
        }),

        searchLeases: builder.query<LeaseSearchResult[], SearchLeasesParams>({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.search,
                    method: HTTP_METHOD.GET,
                    params: params,
                };
            },
            providesTags: [LEASES.LEASES],
        }),
        updateLeaseFields: builder.mutation({
            query: ({ leaseId, landId, ...body }) => {
                return {
                    url:
                        process.env.REACT_APP_LEASE_API_HOST +
                        ENDPOINTS.leases.root +
                        `/${leaseId}/lands/${landId}`,
                    method: HTTP_METHOD.PUT,
                    body,
                };
            },
            invalidatesTags: [LEASES.LEASES],
        }),
    }),
});

export const {
    useUpdateLeaseFieldsMutation,
    useLazySearchLeasesQuery,
    useDeleteLeaseMutation,
    useGetArchivedLeasesQuery,
    useArchiveLeaseMutation,
    useRestoreLeaseMutation,
    useUpdateLeaseDraftMutation,
    useDeleteLeaseDraftMutation,
    useCreateLeaseDraftMutation,
    useCopyLeaseMutation,
    useGetLeaseDraftsQuery,
    useLazyGetLeaseDraftsQuery,
    useLeaseHistoryQuery,
    useGetLeaseListQuery,
    useLazyGetLeaseListQuery,
    useCreateLeaseMutation,
    useGetLeaseDashboardQuery,
    useUpdateLeaseMutation,
    useGetUpcomingLeaseRenewalsQuery,
    useGetOneLeaseQuery,
    useLazyGetOneLeaseQuery,
    useGetRecentUploadsQuery,
    useGetLeaseDocumentsQuery,
    useDocumentsUploadMutation,
} = leaseAPI;
