import { FieldProps, Form } from 'react-final-form';
import { Box } from '@mui/material';
import theme from '../../../styles/theme';

import AppButton from '../../../design/Buttons/AppButton';
import HazardousFormFields from './HazardousFormFields';
import { useEffect } from 'react';

export interface Guideline {
    eventName: string;
    safetyVideos: string[];
    description?: string;
}

interface OwnProps {
    input: FieldProps<string[], never>;
    guidelines: Guideline[];
    setGuidelines: (guidelines: Guideline[]) => void;
}

export default function HazardousForm({
    input,
    guidelines,
    setGuidelines,
}: OwnProps) {
    function addGuideline(values: Guideline) {
        setGuidelines([...guidelines, values]);
    }

    useEffect(() => {
        input.onChange(guidelines);
    }, [guidelines]);

    return (
        <Box
            p={theme.spacing(2)}
            bgcolor={theme.palette.lightTurq.lightTurqTint2}
            borderRadius={theme.spacing(1)}>
            <Form
                onSubmit={addGuideline}
                render={({ handleSubmit, form }) => (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            form.reset();
                        }}>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            rowGap={2}>
                            <HazardousFormFields />
                            <AppButton
                                sx={{
                                    alignSelf: 'flex-end',
                                }}
                                variant={'outlined'}
                                onClick={handleSubmit}
                                title={'Add Guideline'}
                            />
                        </Box>
                    </form>
                )}
            />
        </Box>
    );
}
