import React, { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { TableColumn } from '../../design/GenericTable/GenericTable';
import { formatCurrency } from '../../utils/fieldMasks';
import { FsaId } from './LeasePayments';
import theme from '../../styles/theme';
import { ReactComponent as InfoIcon } from '../../assets/icons/info_icon.svg';
import { useParams } from 'react-router-dom';
import { useGetOneLeaseQuery } from '../../api/leases/leaseAPI';
import { Lease } from '../PaymentsOverview/interfaces';

export default function useFieldTable() {
    const [isShowing, setIsShowing] = useState(false);
    const [landData, setLandData] = useState<FsaId>();
    const { id } = useParams();
    const { data } = useGetOneLeaseQuery(id);

    const loadFsaIds = (lease: Lease) => {
        if (lease) return lease.fsaIds;
        return [];
    };
    function handleInfoClick(land: FsaId) {
        setIsShowing(true);
        setLandData(land);
    }
    function handleClose() {
        setLandData(undefined);
        setIsShowing(false);
    }
    const columns: TableColumn<FsaId>[] = [
        {
            header: {
                label: 'Field Name',
                sortable: true,
                sorting: { id: 'landName', direction: 'asc' },
            },
            cellRender: (land) => (
                <Typography
                    variant={'font14'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    {land.landName}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'FSA ID',
                sortable: true,
                sorting: { id: 'fsaId', direction: 'asc' },
            },
            cellRender: (land) => (
                <Typography
                    variant={'font14'}
                    color={'text.link'}
                    fontWeight={'medium'}>
                    {land.fsaId}
                </Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Tract/TFP Number(s)',
                sortable: true,
                sorting: { id: 'ftpNumber', direction: 'asc' },
            },
            cellRender: (tableData) => (
                <>
                    {tableData.tfpNumber?.map((number, index) => (
                        <Box key={index}>
                            <Typography
                                variant={'font14'}
                                color={'text.link'}
                                fontWeight={'medium'}>
                                {number}
                            </Typography>
                        </Box>
                    ))}
                </>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total Acres',
                sortable: true,
                sorting: { id: 'totalAcres', direction: 'desc' },
            },
            cellRender: (land) => <Typography>{land.totalAcres}</Typography>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Tillable Acres',
                sortable: true,
                sorting: { id: 'tillableAcres', direction: 'desc' },
            },
            cellRender: (land) => <Typography>{land.tillableAcres}</Typography>,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Rent Per Acre',
                sortable: true,
                sorting: { id: 'rentPerAcreRate', direction: 'desc' },
            },
            cellRender: (land) => (
                <Typography>{formatCurrency(land.rentPerAcreRate)}</Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Total Annual Rent',
                sortable: true,
                sorting: { id: 'totalAnnualRent', direction: 'desc' },
            },
            cellRender: (land) => (
                <Typography>{formatCurrency(land.totalAnnualRent)}</Typography>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Info',
                sortable: false,
            },
            cellRender: (land) => (
                <Button
                    variant="outlined"
                    onClick={() => handleInfoClick(land as FsaId)}
                    sx={{
                        color: theme.palette.brandLightTurq,
                        fontWeight: 600,
                        fontSize: 12,
                    }}
                    endIcon={
                        <InfoIcon
                            style={{
                                fill: theme.palette.brandLightTurq,
                                width: 16,
                                height: 16,
                            }}
                        />
                    }>
                    Details
                </Button>
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ];

    const tableConfig = {
        columns,
        pageable: false,
        multiselect: false,
    };
    const totalCount = loadFsaIds(data)?.length;
    return {
        data: loadFsaIds(data),
        totalCount,
        tableConfig,
        handleClose,
        landData,
        isShowing,
    };
}
