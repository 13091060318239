import { ApproachingYearlyCheckups } from './useApproachingYearlyCheckupsTable';

export const approachingYearlyCheckupsMockData: ApproachingYearlyCheckups[] = [
    {
        id: '1',
        name: 'Lewis Carroll',
        phone: '(917) 331-5555',
        solution: 'Emergency Readiness',
        completedOnDate: '369 days ago',
        checkupDate: '9/24/24',
    },
    {
        id: '2',
        name: 'Alice Liddell',
        phone: '(555) 777-8899',
        solution: 'Emergency Readiness',
        completedOnDate: '358 days ago',
        checkupDate: '11/10/24',
    },
    {
        id: '3',
        name: 'Charles Dodgson',
        phone: '(917) 331-5555',
        solution: 'Emergency Readiness',
        completedOnDate: '9/25/2023',
        checkupDate: 'tomorrow',
    },
    {
        id: '4',
        name: 'Frances Jane Lutwidge',
        phone: '(917) 331-5555',
        solution: 'Emergency Readiness',
        completedOnDate: '9/25/2023',
        checkupDate: 'in 5 days',
    },
    {
        id: '5',
        name: 'John Ruskin',
        phone: '(917) 331-5555',
        solution: 'Emergency Readiness',
        completedOnDate: '9/25/2023',
        checkupDate: 'in 20 days',
    },
];
