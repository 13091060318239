import FormScreenWrapper from './FormScreenWrapper';
import { Box } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import { FormConfig } from '../../design/Forms/interfaces';

import FormPage from '../../reusable/FormUI/FormPage';

export default function ExternalAssistanceForm() {
    return (
        <FormScreenWrapper title="External Assistance">
            <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                <AppTypography
                    flexWrap={true}
                    variant="font16"
                    fontWeight={'medium'}
                    text={
                        'Are you familiar with government disaster assistance programs for farmers?'
                    }
                    color={'text.neutral9'}
                />
                <FormPage formConfig={emergencyDrillConfig} />
            </Box>
        </FormScreenWrapper>
    );
}

const yesNoOptions = [
    { id: 'YES', name: 'Yes' },
    { id: 'NO', name: 'No, I’d like to learn more' },
];

const emergencyDrillConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'full',
            name: 'trainingPreparedness.emergencyDrill.disasterAssistanceFamiliarity',
            type: 'radio',
            label: '',
            isRequired: true,
        },
        renderProps: {
            options: yesNoOptions,
            isDisabled: false,
            size: 'third',
        },
    },
];
