import React, { useState } from 'react';
import OtherServicesCard, { CardOtherServices } from './OtherServicesCard';
import { Box, Button, Grid } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import sowerLogo from '../../assets/images/sowerLogo.png';
import compeer from '../../assets/images/compeer.png';
import nationWide from '../../assets/images/nationWide.png';
import smallFarms from '../../assets/images/smallFarms.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sower2Logo from '../../assets/images/sowerSecondLogo.webp';
import { ReactComponent as RotateIcon } from '../../assets/icons/rotate_icon.svg';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/link_external_icon.svg';
import GridOrListGroupButton from './GridOrListGroupButton';

import OtherServicesFilter from './OtherServicesFilter';
import theme from '../../styles/theme';

export default function OtherServicesWrappedCardList() {
    const [type, setType] = useState<'grid' | 'list'>('grid');

    const [filterSettings, setFilterSettings] = useState<string | undefined>(
        undefined,
    );
    const handleReset = () => {
        setFilterSettings(undefined);
    };

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} columnGap={2} alignItems={'center'}>
                    <AppTypography
                        variant={'font16'}
                        color={'text.secondary'}
                        fontWeight={'medium'}
                        text={'Active Filter:'}
                    />

                    <OtherServicesFilter
                        setFilterSettings={setFilterSettings}
                    />
                    <Button
                        variant={'text'}
                        onClick={handleReset}
                        disabled={!filterSettings}
                        endIcon={
                            <RotateIcon
                                style={{
                                    fill: !filterSettings
                                        ? theme.palette.neutral.neutral3
                                        : theme.palette.primary.main,
                                }}
                            />
                        }>
                        Reset View
                    </Button>
                </Box>
                <Box display={'flex'} columnGap={2} alignItems={'center'}>
                    <AppTypography
                        variant={'font16'}
                        color={'text.secondary'}
                        fontWeight={'medium'}
                        text={'Layout:'}
                    />
                    <GridOrListGroupButton type={type} setType={setType} />
                </Box>
            </Box>
            <Grid
                flexDirection={type === 'grid' ? 'row' : 'column'}
                container
                spacing={3}
                flex={1}>
                {Config().map((item, index) => (
                    <Grid item key={index} xs={type === 'grid' ? 6 : 12}>
                        <OtherServicesCard key={index} {...item} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

function Config(): CardOtherServices[] {
    return [
        {
            leftSide: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={sowerLogo} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'721 Exchange for Farmland'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'Exchange farmland for partnership units in the Legacy Farmland Fund. Converting a farm into Fund Units provides farm owners with capital gains deferment, diversification, control over their investment and their legacy. Plus, Fund Units have built-in flexibility to retain, sell, gift or divide shares to heirs efficiently and fairly.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                endIcon: <ExternalLinkIcon />,
                onClick: () => {
                    window.open(
                        'http://40201061.hs-sites.com/farmland-investment-opportunities-with-sower-farmland-the-legacy-farmland-fund/',
                        '_blank',
                    );
                },
            },
        },
        {
            leftSide: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={compeer} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'Farm Operating Loans'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'Farmland purchase loans tailored to your needs, with competitive rates and flexible payment options. These are long-term loans to finance or refinance farmland, pastureland, and other ground used for agricultural purposes. You can also use these loans for land improvements, such as fencing, grain bins, and facilities.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                endIcon: <ExternalLinkIcon />,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
            },
        },
        {
            leftSide: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={sower2Logo} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'Gain Access to Farmland as an Investment'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'Farmland represents true diversification within an investment portfolio. The Sower Farmland Fund provides investors with access to this lucrative and inflation-hedging asset.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                endIcon: <ExternalLinkIcon />,
                onClick: () => {
                    window.open(
                        'https://40201061.hs-sites.com/farmland-investment-opportunities-with-sower-farmland-the-legacy-farmland-fund-1',
                        '_blank',
                    );
                },
            },
        },
        {
            leftSide: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={nationWide} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'Liability Insurance'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'Farmland purchase loans tailored to your needs, with competitive rates and flexible payment options. These are long-term loans to finance or refinance farmland, pastureland, and other ground used for agricultural purposes. You can also use these loans for land improvements, such as fencing, grain bins, and facilities.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                endIcon: <ExternalLinkIcon />,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
            },
        },
        {
            leftSide: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={nationWide} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'Personal Property'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'Farmland purchase loans tailored to your needs, with competitive rates and flexible payment options. These are long-term loans to finance or refinance farmland, pastureland, and other ground used for agricultural purposes. You can also use these loans for land improvements, such as fencing, grain bins, and facilities.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                endIcon: <ExternalLinkIcon />,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
            },
        },
        {
            leftSide: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={nationWide} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'Machinery + Equipment'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'Farmland purchase loans tailored to your needs, with competitive rates and flexible payment options. These are long-term loans to finance or refinance farmland, pastureland, and other ground used for agricultural purposes. You can also use these loans for land improvements, such as fencing, grain bins, and facilities.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                endIcon: <ExternalLinkIcon />,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
            },
        },
        {
            leftSide: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={smallFarms} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'Social Media Training'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'Farmland purchase loans tailored to your needs, with competitive rates and flexible payment options. These are long-term loans to finance or refinance farmland, pastureland, and other ground used for agricultural purposes. You can also use these loans for land improvements, such as fencing, grain bins, and facilities.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                endIcon: <ExternalLinkIcon />,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
            },
        },
    ];
}
