import { FieldProps } from 'react-final-form';
import { ContactData } from './model';
import { Box } from '@mui/material';
import SearchContactField from './SearchContactField/SearchContactField';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LeadCard from './LeadCard';

interface OwnProps {
    input: FieldProps<ContactData, never>;
}
export default function LeadField({ input }: OwnProps) {
    const { id } = useParams();
    const { value, onChange } = input;
    const [lead, setLead] = useState<ContactData | undefined>(value);

    function handleSelectOption(option: ContactData) {
        setLead(option);
    }

    function handleRemoveLead() {
        setLead(undefined);
    }

    useEffect(() => {
        lead && onChange(lead);
    }, [lead]);

    return (
        <Box>
            {!lead?.id && (
                <SearchContactField
                    onSelect={handleSelectOption}
                    organizationId={id}
                />
            )}

            {lead && (
                <LeadCard data={lead} handleRemoveContact={handleRemoveLead} />
            )}
        </Box>
    );
}
