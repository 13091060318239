import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Loader from '../../design/BaseLoader';
import GenericTable from '../../design/GenericTable/GenericTable';
import PreviewModal from '../../reusable/Modal/PreviewModal';
import useGuestDocumentsTable from './useGuestDocumentsTable';
import theme from '../../styles/theme';
import { makeStyles } from 'tss-react/mui';

export default function GuestsDocuments() {
    const {
        tableConfig,
        isLoading,
        isDocumentLoading,
        documents,
        isShowingDocument,
        file,
        closeDocumentModal,
        fileExtension,
        fileName,
    } = useGuestDocumentsTable();
    const { classes } = useStyles();
    if (isLoading)
        return (
            <Grid container justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    const name = documents?.[0]?.firstName
        ? documents?.[0]?.firstName + documents?.[0]?.lastName
        : '';
    return (
        <>
            <Box className={classes.background}>
                <Box
                    width={'100%'}
                    p={theme.spacing(2, 2)}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    zIndex={2}>
                    <Typography
                        variant="font14"
                        color={'text.neutralBlack'}
                        fontWeight={'bold'}
                        style={{ flexGrow: 1 }}>
                        {`Documents for ${name} `}
                    </Typography>
                </Box>
            </Box>
            <Box
                height={'calc(100vh - 75px)'}
                bgcolor={'backgroundB.backgroundB2'}
                p={4}
                display={'flex'}
                flexDirection={'column'}>
                <Box width={800}>
                    <GenericTable data={documents} tableConfig={tableConfig} />
                </Box>
                {fileExtension && (
                    <PreviewModal
                        extension={fileExtension}
                        fileName={fileName}
                        file={file}
                        isLoading={isDocumentLoading}
                        isShowing={isShowingDocument}
                        onClose={closeDocumentModal}
                    />
                )}
            </Box>
        </>
    );
}
const useStyles = makeStyles()(() => ({
    background: {
        display: 'flex',
        width: `100%`,
        height: 75,
        backgroundColor: theme.palette.limeGreen.limeGreenTint9,
    },
}));
