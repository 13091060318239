import { useEffect } from 'react';
import { useLazySearchLPQuery } from '../../api/landpartners/landPartnersAPI';
import FormModal from '../../reusable/Modal/FormModal';
import { FormConfig } from '../../design/Forms/interfaces';
import { composeValidators, required } from '../../utils/validation';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../router/paths';
import customToastWithAlert from '../../design/CustomToastWithAlert';

interface EmergencyModalProps {
    isShowing: boolean;
    onClose: () => void;
}

interface EmergencySelectForm {
    organizationId: {
        id: string;
        name: string;
    };
}

interface Organization {
    id: string;
    name: string;
}

export default function EmergencyReadinessModal({
    isShowing,
    onClose,
}: EmergencyModalProps) {
    const navigate = useNavigate();
    const [getLandPartners, { data: landPartnerOptions }] =
        useLazySearchLPQuery();

    useEffect(() => {
        getLandPartners({ page: 0, size: 2000 });
    }, [getLandPartners]);

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = (value: EmergencySelectForm) => {
        const { organizationId } = value || {};

        if (!organizationId) {
            return customToastWithAlert({
                type: 'error',
                message:
                    'Please select an Organization to startEmergency Readiness',
            });
        }
        navigate(
            `/${PATHS.services}/${PATHS.emergencyReadiness}/${PATHS.create}?organizationId=${organizationId?.id}`,
        );
    };

    return (
        <FormModal
            header="Initiate Emergency Readiness"
            isShowing={isShowing}
            formConfig={createDisasterPlanningFormConfig(
                landPartnerOptions || [],
            )}
            handleSubmit={handleSubmit}
            submitButtonText="Start Readiness"
            onClose={handleClose}
        />
    );
}

const createDisasterPlanningFormConfig = (
    organizations: Organization[],
): FormConfig[] => {
    return [
        {
            formField: {
                scheme: 'full',
                name: 'organizationId',
                type: 'searchAutocomplete',
                label: 'Select Organization for Emergency Readiness Plan',
                isRequired: true,
                validation: composeValidators(required),
            },
            renderProps: {
                isDisabled: false,
                size: 'small',
                options: organizations,
            },
        },
    ];
};
